<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'addBranch' })"
      :showAddBtn="$perHelper('branches:create')"
    >
      {{ $t("main.branches") }}
    </dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/branches'"></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        // '#',
        { label: this.$t("main.name"), key: "name", class: "text-start" },
        { label: this.$t("main.address"), key: "address", class: "text-start" },
        { label: this.$t("main.city"), key: "city.name", class: "text-start" },
        {
          label: this.$t("main.area"),
          key: "area.name",
          class: "text-start",
        },
        {
          label: this.$t("products.control"),
          key: "actions",
          class: "text-start",
          type: "actions",
          actions: [
            {
              text: "edit",
              icon: "ri-ball-pen-fill",
              color: "info",
              ifNavigate: true,
              routePath: "edit-branches/:id",
              showIf: () => this.$perHelper("branches:update"),
            },
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "merchant/branches",
              titleHeader: "منتج",
              question: "هل متأكد أنك تريد مسح هذا الفرع",
              textContent: "name",
              textDeleteButton: "بالتأكيد, مسح",
              textCancelButton: "الغاء",
              showAlert: true,
              // showIf: () => this.$perHelper('branches:soft_delete')
            },
          ],
        },
      ],
    };
  },
  methods: {
    addMerchant() {
      console.log("hello");
    },
  },
};
</script>
